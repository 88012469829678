var exports = {};

exports = function (hljs) {
  /* Variables: simple (eg $(var)) and special (eg $@) */
  var VARIABLE = {
    className: "variable",
    variants: [{
      begin: "\\$\\(" + hljs.UNDERSCORE_IDENT_RE + "\\)",
      contains: [hljs.BACKSLASH_ESCAPE]
    }, {
      begin: /\$[@%<?\^\+\*]/
    }]
  };
  /* Quoted string with variables inside */

  var QUOTE_STRING = {
    className: "string",
    begin: /"/,
    end: /"/,
    contains: [hljs.BACKSLASH_ESCAPE, VARIABLE]
  };
  /* Function: $(func arg,...) */

  var FUNC = {
    className: "variable",
    begin: /\$\([\w-]+\s/,
    end: /\)/,
    keywords: {
      built_in: "subst patsubst strip findstring filter filter-out sort " + "word wordlist firstword lastword dir notdir suffix basename " + "addsuffix addprefix join wildcard realpath abspath error warning " + "shell origin flavor foreach if or and call eval file value"
    },
    contains: [VARIABLE]
  };
  /* Variable assignment */

  var VAR_ASSIG = {
    begin: "^" + hljs.UNDERSCORE_IDENT_RE + "\\s*[:+?]?=",
    illegal: "\\n",
    returnBegin: true,
    contains: [{
      begin: "^" + hljs.UNDERSCORE_IDENT_RE,
      end: "[:+?]?=",
      excludeEnd: true
    }]
  };
  /* Meta targets (.PHONY) */

  var META = {
    className: "meta",
    begin: /^\.PHONY:/,
    end: /$/,
    keywords: {
      "meta-keyword": ".PHONY"
    },
    lexemes: /[\.\w]+/
  };
  /* Targets */

  var TARGET = {
    className: "section",
    begin: /^[^\s]+:/,
    end: /$/,
    contains: [VARIABLE]
  };
  return {
    aliases: ["mk", "mak"],
    keywords: "define endef undefine ifdef ifndef ifeq ifneq else endif " + "include -include sinclude override export unexport private vpath",
    lexemes: /[\w-]+/,
    contains: [hljs.HASH_COMMENT_MODE, VARIABLE, QUOTE_STRING, FUNC, VAR_ASSIG, META, TARGET]
  };
};

export default exports;